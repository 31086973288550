<script setup lang="ts">
interface IProps {
  modelValue: string;
  type: string;
  placeholder?: string | null;
  readonly?: boolean;
  disabled?: boolean;
  required?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  type: "text",
  readonly: false,
  disabled: false,
  required: false,
});

const emit = defineEmits(['update:modelValue']);

const inputValue = ref(props.modelValue);

watch(() => props.modelValue, (newValue) => {
  inputValue.value = newValue;
});

watch(inputValue, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>

<template>
  <input
      id="password"
      :type="type"
      v-model="inputValue"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      :required="required"
      class="px-3 py-4 w-full border text-zinc-900 dark:!text-white border-primary bg-gray-100 dark:bg-zinc-950 rounded-xl focus:outline-none"
  />
</template>

<style scoped>

</style>